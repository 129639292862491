import { useAuth0 } from '@auth0/auth0-react'
import { RouteComponentProps, useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { parse } from 'querystringify'
import React from 'react'
import useSwitchToSubDomain from '~/hooks/useSwitchToSubDomain'
import { login } from '~/utils/auth0'
/**
 * Gatsby doesn't understand this syntax yet
 * export { default } from 'thing'
 *
 * https://github.com/gatsbyjs/gatsby/issues/12384
 */
export default function RedirectToAuth0(_: RouteComponentProps) {
  const { isAuthenticated } = useAuth0()
  const { search } = useLocation()
  const params = parse(search) as {
    connection?: string
    target_uid?: string
  }
  const { AUTH_URL } = useSwitchToSubDomain()
  React.useEffect(() => {
    if (isAuthenticated) navigate(`/member${search || ''}`)
    else
      login({
        connection: params.connection || 'Username-Password-Authentication',
        target_uid: params.target_uid,

        // forward any UTM params
        redirectUri: `${AUTH_URL}/authorize?redirect=/member${search || ''}`,
      })
  }, [isAuthenticated, params, search, AUTH_URL])

  return <span />
}
